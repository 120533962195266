import React, { useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import Button from "../UI/Button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import "./form.css";
import { InputTextarea } from "primereact/inputtextarea";
import axios from "axios";

export const ContactForm = () => {
	const [showMessage, setShowMessage] = useState(false);
	const [formData, setFormData] = useState({});

	const formik = useFormik({
		initialValues: {
			name: "",
			email: "",
			comment: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.name) {
				errors.name = "Name is required.";
			}
			if (!data.email) {
				errors.email = "Email is required.";
			} else if (
				!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
			) {
				errors.email = "Invalid email address. E.g. example@email.com";
			}
			if (!data.comment) {
				errors.comment = "Please add detail messages.";
			}

			return errors;
		},
		onSubmit: async (data) => {
			setFormData(data);
			const resp = await SendEmail(data);
			const conditions = ["ERROR", "FAILED", "403"];
			// run the tests against every element in the array
			const test1 = conditions.some((el) => resp.includes(el));
			if (!test1) setShowMessage(true);

			formik.resetForm();
		},
	});

	const isFormFieldValid = (name) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};

	const dialogFooter = (
		<div className="flex justify-content-center">
			<Button
				text="OK"
				className="p-button-text"
				autoFocus
				onClick={() => setShowMessage(false)}
			/>
		</div>
	);
	const commentHeader = <h6>Enter Message ...</h6>;

	const SendEmail = async (data) => {
		try {
			const URL = process.env.REACT_APP_SERVER_URL + "/sendgmail";
			const response = await axios
				.post(URL, {
					name: data.name,
					email: data.email,
					comments: data.comment,
				})
				.then((res) => {
					if (res && res.data) return res.data;
					else return [];
				});
			return response;
		} catch (err) {
			return err;
		}
	};

	return (
		<div className="form-demo">
			<Dialog
				visible={showMessage}
				onHide={() => setShowMessage(false)}
				position="top"
				footer={dialogFooter}
				showHeader={false}
				breakpoints={{ "960px": "80vw" }}
				style={{ width: "30vw" }}
			>
				<div className="flex align-items-center flex-column pt-6 px-3">
					<i
						className="pi pi-check-circle"
						style={{ fontSize: "5rem", color: "var(--green-500)" }}
					></i>
					<h5>Mail Sent Successful!</h5>
					<p style={{ lineHeight: 1.5, textIndent: "1rem" }}>
						We will look at the message and contact you <b>{formData.name}</b>{" "}
						if we have any questions. Please check <b>{formData.email}</b> and
						the website for updates.
					</p>
				</div>
			</Dialog>

			<div className="flex justify-content-center">
				<div className="card p-3">
					<h4 className="text-center">Contact Admin Form</h4>
					<form onSubmit={formik.handleSubmit} className="p-fluid">
						<div className="field">
							<span className="p-float-label">
								<InputText
									id="name"
									name="name"
									value={formik.values.name}
									onChange={formik.handleChange}
									autoFocus
									className={classNames({
										"p-invalid": isFormFieldValid("name"),
									})}
								/>
								<label
									htmlFor="name"
									className={classNames({
										"p-error": isFormFieldValid("name"),
									})}
								>
									Your Name*
								</label>
							</span>
							{getFormErrorMessage("name")}
						</div>
						<div className="field">
							<span className="p-float-label p-input-icon-right">
								{/* <i className="mr-5 pi pi-envelope" /> */}
								<InputText
									id="email"
									name="email"
									value={formik.values.email}
									onChange={formik.handleChange}
									className={classNames({
										"p-invalid": isFormFieldValid("email"),
									})}
								/>
								<label
									htmlFor="email"
									className={classNames({
										"p-error": isFormFieldValid("email"),
									})}
								>
									Your Email*
								</label>
							</span>
							{getFormErrorMessage("email")}
						</div>
						<div className="field">
							<span className="p-float-label">
								<InputTextarea
									autoResize
									id="comment"
									name="comment"
									value={formik.values.comment}
									onChange={formik.handleChange}
									toggleMask
									className={classNames({
										"p-invalid": isFormFieldValid("comment"),
									})}
									header={commentHeader}
								/>
								<label
									htmlFor="comment"
									className={classNames({
										"p-error": isFormFieldValid("comment"),
									})}
								>
									Comment*
								</label>
							</span>
							{getFormErrorMessage("comment")}
						</div>

						<Button type="submit" text="Submit" className="mt-2" />
					</form>
				</div>
			</div>
		</div>
	);
};
