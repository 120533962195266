import React, { useState } from "react";
import "./Button.css";
export default function Button(props) {
	const { text, size, color, variant, onClick, ...other } = props;
	const [active, setActive] = useState("");

	return (
		<button
			className={active === "1" ? "active" : undefined}
			style={{ marginRight: "5px" }}
			onClick={onClick}
		>
			{text}
		</button>
	);
}
