import React from "react";
import { Box as MuiBox } from "@mui/material";

export default function Box(props) {
	const { children } = props;

	return (
		<MuiBox
			alignContent={"center"}
			marginTop={"12px"}
			paddingLeft={"12px"}
			paddingRight={"12px"}
			paddingBottom={"10px"}
			marginBottom={"10px"}
			border={1}
			borderLeft={1}
			borderRight={1}
			borderColor="#5499C7"
		>
			{children}
		</MuiBox>
		// <MuiButton
		// 	variant={variant || "contained"}
		// 	size={size || "medium"}
		// 	color={color}
		// 	style={{
		// 		padding: "10px 15px",
		// 		marginRight: "5px",
		// 		width: "120px",
		// 		height: "20px",
		// 	}}
		// 	onClick={onClick}
		// 	{...other}
		// >
		// 	{text}
		// </MuiButton>
	);
}
