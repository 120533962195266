import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { debug } from '../utils/ConsoleHelper';

//// using zustand to store sharing privilege data between components
const useZustandToken = create(
	persist(
		(set, get) => ({
			update: false,
			setUpdate: async () => set((state) => ({ update: !state.count })),
			search: null,
			setSearch: async (ud) => set({ search: ud }),
			token: undefined,
			setToken: async (idata) => { set(() => ({ token: idata })) },
		}),
		{
			name: 'token-store', // name of the item in the storage (must be unique)
			storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
		}
	)
);

export default useZustandToken;
