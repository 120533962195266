import React from "react";
import Card from "../UI/Card";

export function NotFound(props) {
	return (
		<Card>
			<h2>404 Page Not Found On this Server!</h2>
		</Card>
	);
}
