import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ReactPanZoom from "react-image-pan-zoom-rotate";

const PreviewModal = ({
	clickedImg,
	setClickedImg,
	handelRotationRight,
	handelRotationLeft,
}) => {
	const handleClick = (e) => {
		if (e.target.classList.contains("dismiss")) {
			setClickedImg(null);
		}
	};

	return (
		<>
			<div className="overlay dismiss" onClick={handleClick}>
				<span className="dismiss" onClick={handleClick}>
					close [X]
				</span>
				<ReactPanZoom image={clickedImg} alt="Use Mouse Wheel to Zoom in/out" />
				<div onClick={handelRotationLeft} className="overlay-arrows_left">
					<div>
						<ArrowBackIcon />
					</div>
				</div>
				<div onClick={handelRotationRight} className="overlay-arrows_right">
					<div>
						<ArrowForwardIcon />
					</div>
				</div>
			</div>
		</>
	);
};

export default PreviewModal;
