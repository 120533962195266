import React, {
	useState,
	useEffect,
	useCallback,
} from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "./Preview.css";
import PreviewModal from "./PreviewModal";
import axios from "axios";
import Loading from "../assets/loading.png";
import useZustandToken from "../useZustandToken";
import { useLocation } from "react-router-dom";
import { Card } from "@mui/material";

const PreviewDirectoryImages = (props) => {
	const { token, setToken } = useZustandToken() //  useToken();
	const [imagesPreviewUrls, setImagesPreviewUrls] = useState([]);
	const [previewImages, setPreviewImages] = useState([]);
	const [dragId, setDragId] = useState("");
	const [clickedImg, setClickedImg] = useState(null);
	const [currentIndex, setCurrentIndex] = useState(null);
	const [loading, setLoading] = useState(false);
	const { personid } = props;
	let urllocation = useLocation();
	const dirstr = new URLSearchParams(urllocation.search).get('dir')

	useEffect(() => {
		if (dirstr) {
			handleUploadImageFromServer(dirstr);
		}
	}, [dirstr]);

	useEffect(() => {
		const { imagesPreviewUrls } = props;
		setPreviewImages(imagesPreviewUrls);
	}, [props.imagesPreviewUrls]);

	const handleClick = (item, index) => {
		setCurrentIndex(index);
		setClickedImg(item.file);
	};

	const get = async (url) => {
		try {
			const response = await fetch(url, {
				method: "GET", // *GET, POST, PUT, DELETE, etc.
				mode: "cors", // no-cors, *cors, same-origin
				cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
				headers: {
					id: personid,
				},
			}).then((res) => {
				return res.json();
			});
			return [response, null];
		} catch (error) {
			return [null, error];
		}
	};

	const handleUploadImageFromServer = async (dirstr) => {
		setLoading(true);
		const url = process.env.REACT_APP_SERVER_URL + `/fetchImagesFromDirectory/${dirstr}`;
		const [response, error] = await get(url);
		if (error) {
			//alert(error);
			setLoading(false);
		} else {
			if (response) {
				let tmpfiles = imagesPreviewUrls;
				if (response && response.length > 0) {
					response?.map((rec) => {
						// const tmp = `data:image/jpeg;base64,${rec.file}`;
						const record = {
							file: process.env.REACT_APP_IMG_SERVER_URL + "/images/" + rec,
							id: rec,
							filename: rec,
						};
						tmpfiles = [...tmpfiles, record];
					});
				}
				setPreviewImages(tmpfiles);
				setImagesPreviewUrls(tmpfiles);
				setLoading(false);
			}
		}
	};

	const deleteImage = async (file) => {
		const url =
			process.env.REACT_APP_SERVER_URL + `/deleteImage/${file.filename}`;

		await axios
			.post(url, {
				headers: {
					id: personid,
					filename: file.filename,
				},
			})
			.then((response) => {
				//// delete image from GUI client
				const { deleteImage } = props;
				deleteImage(file.id);
				console.log(`image deleted from server => ${response.data}`);
			})
			.catch((err) => {
				//alert(err);
			});
	};

	const handelRotationRight = () => {
		const totalLength = previewImages.length;
		if (currentIndex + 1 >= totalLength) {
			setCurrentIndex(0);
			const newUrl = previewImages[0].file;
			setClickedImg(newUrl);
			return;
		}
		const newIndex = currentIndex + 1;
		const newUrl = previewImages.filter((item) => {
			return previewImages.indexOf(item) === newIndex;
		});
		const newItem = newUrl[0].file;
		setClickedImg(newItem);
		setCurrentIndex(newIndex);
	};

	const handelRotationLeft = () => {
		const totalLength = previewImages.length;
		if (currentIndex === 0) {
			setCurrentIndex(totalLength - 1);
			const newUrl = previewImages[totalLength - 1].file;
			setClickedImg(newUrl);
			return;
		}
		const newIndex = currentIndex - 1;
		const newUrl = previewImages.filter((item) => {
			return previewImages.indexOf(item) === newIndex;
		});
		const newItem = newUrl[0].file;
		setClickedImg(newItem);
		setCurrentIndex(newIndex);
	};

	const handleOver = (ev) => {
		ev.preventDefault();
	};

	const handleDrag = (ev) => {
		setDragId(ev.currentTarget.id);
	};

	const handleDrop = (ev) => {
		ev.preventDefault();
		const dragImage = previewImages.find((image) => image.id == dragId);
		const dropImage = previewImages.find(
			(image) => image.id == ev.currentTarget.id
		);
		const arr = moveItem(dragImage.id - 1, dropImage.id - 1);

		setPreviewImages(arr);
	};

	const moveItem = (from, to) => {
		const f = previewImages.splice(from, 1)[0];
		previewImages.splice(to, 0, f);
		return previewImages;
	};

	const renderPreview = () => {
		if (previewImages?.length > 0) {
			previewImages.map((items, index) => {
				items.id = index + 1;
			});
		}

		return (
			<Card >
				{loading && (
					<div className="gallery">
						<img src={Loading} alt="Loading" /> Loading Images ....{" "}
					</div>
				)}
				{previewImages &&
					previewImages?.length > 0 &&
					previewImages.map((element, index) => {
						return (
							<div
								photo={element}
								index={index}
								className="gallery"
								key={index}
								id={element.id}
								draggable
								onDragOver={(e) => handleOver(e)}
								onDragStart={(e) => handleDrag(e)}
								onDrop={(e) => handleDrop(e)}
							>
								<img
									src={element.file}
									name={element.filename}
									alt={element.filename}
									width="200"
									height="200"
									onClick={() => handleClick(element, index)}
								/>
								{/* {token && token?.token == "admin" && (
									<div className="desc">
										<div className="image-order">
											<FontAwesomeIcon
												className="delete-icon"
												onClick={() => deleteImage(element)}
												icon={faTrash}
											/>
										</div>
									</div>
								)} */}
							</div>
						);
					})}
				<div>
					{clickedImg && (
						<PreviewModal
							clickedImg={clickedImg}
							handelRotationRight={handelRotationRight}
							setClickedImg={setClickedImg}
							handelRotationLeft={handelRotationLeft}
						/>
					)}
				</div>
			</Card>
		);
	};

	return <div className="previewwrapper">{renderPreview()}</div>;
};

export default PreviewDirectoryImages;
