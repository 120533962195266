import React from 'react';

export const PhotoCard = ({ owner, imageUrl, onClick }) => {
    return (
        <div class="w-5 rounded overflow-hidden shadow-lg m-2">
            <img class="w-full h-64 object-center" src={imageUrl} onclick={onClick} />
            {/* <div class="px-6 py-4">
                <div class="font-regular text-xs mb-1">{owner}</div>
            </div> */}
        </div>
    );
}