import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNavigate, useLocation } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import Card from "../UI/Card";
import { TextField, Grid, Divider } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';
import IconTextField from "../UI/IconTextField";
import { Avatar } from "primereact/avatar";
import { INDIVIDUAL_COLUMNS } from "../../headers/ColumnHeader";
import "./Individuals.css";
import { dbQuery } from "../../api/db";
import Button from "../UI/Button";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import useZustandToken from "../useZustandToken";
const People = (props) => {
	const gridRef = useRef();
	const [gridApi, setGridApi] = useState(null);
	let col_headers = INDIVIDUAL_COLUMNS;
	const [rawData, setRawData] = useState();
	const navigate = useNavigate();
	const [columnDefs] = useState(col_headers);
	const { update, search, setSearch } = useZustandToken()
	const [curLN, setCurLN] = useState("NGO")

	//// GETTING ALL DEVICES
	useEffect(() => {
		getData("SELECT * from NGO ORDER BY personID ASC");
	}, []);

	useEffect(() => {
		getData(`SELECT * from ${curLN} ORDER BY personID ASC`);
	}, [update]);

	const initSizeAll = useCallback((skipHeader) => {
	}, []);

	const onGridReady = useCallback((params) => {
		setGridApi(params.api);
		initSizeAll(false);
		if (search) {
			params && params.api.setGridOption("quickFilterText", search);
		}
	});

	const defaultColDef = useMemo(() => {
		return {
			flex: 1,
			minWidth: 200,
			editable: true,
			resizable: true,
			wrapText: true,
			sortable: true,
			cellStyle: {
				textAlign: 'left',
			},
		};
	}, []);

	const onCellClicked = (e) => {
		const to_tree_link = "../tree";
		navigate(to_tree_link, {
			state: {
				data: e.data,
				modify: false,
				viewonly: true,
				dbdata: rawData,
			},
		});
	};

	/// searching box
	const onFilterTextChange = (e) => {
		setSearch(e.target.value)
		gridApi && gridApi.setGridOption("quickFilterText", e.target.value);
	};

	const clearSearchField = (e) => {
		setSearch("")
		gridApi && gridApi.setGridOption("quickFilterText", "");
	}

	const getData = async (querystr) => {
		const data = await dbQuery(querystr);
		setRawData(data);
	};
	const FetchLuong = () => {
		getData("Select * from LUONG ORDER BY personID ASC");
		setCurLN("LUONG")
	};
	const FetchMai = () => {
		getData("Select * from MAI ORDER BY personID ASC");
		setCurLN("MAI")
	};
	const FetchNgo = () => {
		getData("Select * from NGO ORDER BY personID ASC");
		setCurLN("NGO")
	};
	const FetchTrinh = () => {
		getData("Select * from TRINH ORDER BY personID ASC");
		setCurLN("TRINH")
	};
	const FetchBui = () => {
		getData("Select * from BUI ORDER BY personID ASC");
		setCurLN("BUI")
	};

	return (
		<Card className="table">
			<div>
				<h5 style={{ color: "red", margin: "0.5rem" }}> All Individuals</h5>
				{/* <h5 style={{ paddingLeft: "5px", paddingBottom: "5x", color: "red" }}>
					{"All Individuals"}
				</h5> */}
				<div>
					<span className="p-input-icon-left pr-1 h-2">

						<IconField iconPosition="left">
							<InputIcon className="pi pi-search"> </InputIcon>
							<InputText
								style={{ margin: "2px", height: "16px" }}
								className="p-inputtext-sm ml-2"
								onChange={onFilterTextChange}
								placeholder="Search ..."
								value={search}
							></InputText>
							<InputIcon iconPosition="right" className="pi pi-times ml-2 mr-2" onClick={clearSearchField}> </InputIcon>
						</IconField>
					</span>
					<span className="ml-5">
						<Button onClick={FetchNgo} text="Họ Ngô"></Button>
						<Button onClick={FetchLuong} text="Họ Lương"></Button>
						<Button onClick={FetchTrinh} text="Họ Trịnh"></Button>
						<Button onClick={FetchMai} text="Họ Mai"></Button>
						<Button onClick={FetchBui} text="Họ Bùi"></Button>
					</span>
				</div>
				{rawData && rawData.length > 0 && (
					<div style={{ marginTop: "5px" }} className="ag-theme-alpine">
						<AgGridReact
							headerHeight={25}
							rowHeight={23}
							ref={gridRef}
							defaultColDef={defaultColDef}
							columnDefs={columnDefs}
							editType="fullRow"
							suppressClickEdit={true}
							rowSelection={"single"}
							pagination={true}
							paginationPageSize={rawData.length > 25 ? 25 : rawData.length}
							rowData={rawData}
							onGridReady={onGridReady}
							onCellClicked={onCellClicked}
							alwaysShowHorizontalScroll
						></AgGridReact>
					</div>
				)}
			</div>
		</Card>
	);
};

export default People;
