import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Login.css";
import axios from "axios";
import Button from "../UI/Button";
import Card from "../UI/Card";
import Box from "../UI/Box";

export default function Login({ setToken }) {
	const [username, setUserName] = useState();
	const [password, setPassword] = useState();
	const [msg, setMsg] = useState();

	const loginUser = async (u, p) => {
		const URL = process.env.REACT_APP_SERVER_URL + "/login";
		setMsg("Please wait .....");
		const response = await axios
			.post(URL, {
				username: u,
				password: p,
			})
			.then((res) => {
				if (res && res.data) {
					setMsg("");
					return res.data;
				} else {
					setMsg("Please enter correct username and password!");
					return [];
				}
			});
		return response;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (username && password) {
			const tkn = await loginUser(username?.trim(), password?.trim());
			setToken(tkn);
		} else {
			setMsg("Please fill in username/password");
		}
	};

	const hstyle = {
		marginBottom: "0px",
		color: "#5499C7",
	};
	return (
		<Card>
			<div className="login-wrapper">
				<h2 style={hstyle}>Welcome to</h2>
				<h2 style={hstyle}>The Ngô|Lương|Trịnh|Mai|Bùi</h2>
				<h2 style={{ color: "#5499C7" }}>Family Tree</h2>
				<h2 style={{ color: "#7FB3D5" }}>Please Log In</h2>
				<h5>Use guest/guess to browse</h5>
				<Box
					alignContent={"center"}
					marginTop={"0px"}
					paddingLeft={"12px"}
					paddingRight={"12px"}
					paddingBottom={"12px"}
					marginBottom={"12px"}
					border={1}
					borderLeft={1}
					borderRight={1}
					borderColor="#5499C7"
				>
					<form onSubmit={handleSubmit}>
						<Box border={"1px"} borderColor={"black"}>
							<label>
								<p>Username</p>
								<input
									placeholder="guest"
									type="text"
									onChange={(e) => setUserName(e.target.value)}
								/>
							</label>
							<label>
								<p>Password</p>
								<input
									type="password"
									placeholder="guess"
									onChange={(e) => setPassword(e.target.value)}
								/>
							</label>
							<div style={{ marginTop: "20px" }}>
								<Button type="submit" text="Submit"></Button>
							</div>
						</Box>
					</form>
					<h6 style={{ color: "red" }}>{msg}</h6>
				</Box>
				<p style={{ color: "#27AE60" }}>&copy; hqnfam</p>
			</div>
		</Card>
	);
}

Login.propTypes = {
	setToken: PropTypes.func.isRequired,
};
