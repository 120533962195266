import { debug } from "./ConsoleHelper";

export const DecodeString = (str) => {
	if (str) {
		let nstr = str.replace(/\\u[\dA-F]{4}/gi, (unicode) => {
			return String.fromCharCode(parseInt(unicode.replace(/\\u/g, ""), 16));
		});
		nstr = nstr.replace(/[\u{0080}-\u{FFFF}]/gu, "");
		nstr = nstr.replace("/[^\x00-\x7F]/g", "");
		nstr = nstr.replace(/[^\x20-\x7E]/g, "");
		return nstr.trim();
	}
	return str;
};

const getRootLevel = (treeset, id) => {
	const nset = new Set()
	let idl = id.split('_')
	treeset.forEach(a => {
		debug(a)
		let b = a.split('_')
		debug(b.length)
		if (b.length === idl.length) {
			treeset.add(id)
		}
		else {
			nset.add(b.length)
		}
	})
	debug('Root Level =>', nset.size)
	return nset.size;
}

export const CreateDataTree = (dataset, orootid, selectedperson) => {
	const rootid = DecodeString(orootid);
	const hashTable = Object.create(null);
	dataset.forEach(
		(aData) => (hashTable[DecodeString(aData.id)] = { ...aData, children: [] })
	);
	const dataTree = [];
	try {
		//let count = 0;
		// find root fist
		dataset.forEach((aData) => {
			//const pid = DecodeString(aData.parentID);
			const id = DecodeString(aData.id);
			//debug(`parrent ${pid} id: ${id}`);
			if (id === rootid) {
				//// root node
				hashTable[rootid].expanded = true;
				dataTree.push(hashTable[rootid]);
				//count++;
			} else if (rootid === "NO30") {
				//// HACK
				//// root node
				hashTable[id].expanded = true;
				dataTree.push(hashTable[id]);
			}
		});

		//// using this to control one level down
		let pidlist = new Set();

		dataset.forEach((aData) => {
			const pid = DecodeString(aData.parentID);
			const id = DecodeString(aData.id);
			debug(`parrentid ${pid} id: ${id} rootid: ${rootid}`);
			if (pid) {
				if (hashTable[id] && hashTable[pid]) {
					//// push node into parent
					if (id !== pid && pid === rootid) {
						//// one level up
						hashTable[pid].expanded = true;
						hashTable[pid].children.push(hashTable[id]);
						debug(
							`parrent ${pid} added => child ${id} - ${hashTable[id].name}`
						);
						pidlist.add(id);
					} else if (id !== pid && pid !== rootid) {
						//// only one level down
						if (pidlist.has(pid)) {
							hashTable[pid].expanded = true;
							hashTable[pid].children.push(hashTable[id]);
							// has child
							if (getRootLevel(pidlist, id) <= 2) {
								pidlist.add(id);
								debug(
									`parrent id:${pid} added => child ${id} - ${hashTable[id].name}`
								);
							}
						}
					} else {
						debug(`parrent ${pid} === child ${id}`);
						//hashTable[rootid].children.push(hashTable[id]);
					}
				}
			} else {
				debug(`parrent ${pid} === child ${id}`);
				hashTable[rootid].children.push(hashTable[id]);
			}
		});
	} catch (exception) {
		//alert(exception);
	}
	return dataTree;
};

export const GetNameFromInfo = (info) => {
	const emptystr = "NA";
	if (info) {
		const strarr = info.split(";");
		if (strarr.length > 0) return RemoveHTMLTags(strarr[0]);
		else return emptystr;
	}
	return emptystr;
};

export const RemoveHTMLTags = (str) => {
	if (str === null || str === "") return false;
	else str = str.toString();
	// Regular expression to identify HTML tags in
	// the input string. Replacing the identified
	// HTML tag with a null string.
	return str.replace(/(<([^>]+)>)/gi, "");
};

export const GetLastnameFromID = (str) => {
	if (!str) return undefined;
	if (str.startsWith("NO")) return "NGO";
	else if (str.startsWith("LU")) return "LUONG";
	else if (str.startsWith("BU")) return "BUI";
	else if (str.startsWith("MA")) return "MAI";
	else if (str.startsWith("TR")) return "TRINH";
	else return "";
};
