import React from "react";
//import { TextField } from "@material-ui/core";
import _uniqueId from "lodash/uniqueId";
import InputAdornment from "@mui/material/InputAdornment";
import { TextField } from "@mui/material";

const IconTextField = ({
	iconStart,
	iconEnd,
	InputProps,
	startAdornment,
	endAdornment,
	...props
}) => {
	return (
		<TextField sx={{ "& .MuiInputBase-input": { fontSize: 14, height: 4, padding: 1 } }}
			{...props}
			InputProps={{
				...InputProps,
				startAdornment: iconStart ? (
					<InputAdornment position="start">{iconStart}</InputAdornment>
				) : null,
				endAdornment: iconEnd ? (
					<InputAdornment position="end">{iconEnd}</InputAdornment>
				) : null,
			}}
		/>
	);
};

export default IconTextField;
