import React from "react";
import Card from "../UI/Card";
import { CenterFocusStrong } from "@mui/icons-material";
import { TabView, TabPanel } from "primereact/tabview";
//import useToken from "../useToken";
import useZustandToken from "../useZustandToken";

import Login from "./Login";

function FrontPage() {
	const { token, setToken } = useZustandToken() // useToken();
	if (!token) {
		return <Login setToken={setToken} />;
	}

	return (
		<Card>
			<TabView>
				<TabPanel header="Giới Thiệu">
					<p className="m-9">
						<h2
							className="text-cyan-500"
							style={{ alignContent: CenterFocusStrong }}
						>
							Phả Hệ Liên tộc
						</h2>
						<h3 className="text-orange-500">
							Chân thành cảm tạ ông cố Mai Xuân-Trúc - người đã dành nhiều công
							sức để có nền tảng cho cuốn gia phả này.
						</h3>
						<p className="text-small">
							"Phả hệ liên tộc" nghĩa là gì? Ðịnh nghĩa từng chữ thì "PHẢ" do
							chữ "PHỔ" có nghỉa là quyển sách hoặc quyển sổ biên chép có thứ
							tự. và hai chữ "PHỔ HỆ" hay còn đọc là "PHẢ HỆ" là hệ thống cuả
							các đời trong cùng một họ. "LIÊN TỘC" có nghĩa là các họ tiếp hợp
							với nhau. Vì vậy cuốn "phả hệ liên tộc" này có một mục đích ghi
							lại nguồn gốc của mỗi người, bên nội cũng như họ ngoại. Nhưng một
							điều người làm công việc này còn mong muốn là các con cháu sau này
							biết những ngày giỗ chạp của các tiên nhân để nhớ tới họ trong lời
							cầu nguyện. Vì vậy mong các thân nhân họ hàng vui lòng cho biết
							thêm chi tiết như tên thánh, ngày sinh, ngày mất, có như vậy sẽ
							làm thêm gía trị của cuốn gia phả này. Email cho :
							nguyenngo3@gmail.com
						</p>
						<p className="text-small font-italic">
							Dear children, I have spent a lot of time preparing this "family
							tree ebook". The main purpose of it is to record your family
							ancestors. Another purpose is to remind you of the date of your
							ancestors pass away so that you can remember them in your prayers.
							That is what makes this ebook different from and more meaningful
							than the other "family tree" books.
						</p>
						<p className="text-small ">
							Còn nhiều thiếu sót kính xin thân bằng quyến thuộc rộng lượng tha
							thứ và xin góp ý và bổ túc thêm chi tiết để cho cuốn gia phả này
							ngày thêm phong phú.
						</p>
						<h5>Ngô Ngọc Nguyện Version 2023 Portland, Oregon </h5>
						<h5>nguyenngo3@gmail.com </h5>
					</p>
				</TabPanel>
				<TabPanel header="Tâm Sự">
					<h4 className="text-cyan-500">Cố Mai Xuân Trúc (1917-1997)</h4>
					<p className="m-9">
						Chúng cháu xin phép được thắp một nén hương lòng, để thân thưa cùng
						Tiên-Tổ:
					</p>
					<p className="m-9">
						- Rằng : Sông có nguồn, cây có gốc, con người có Tổ Tiên, Tổ-Tiên
						của chúng cháu không biết ở đâu ? chỉ nhớ rằng: khi có trí khôn,
						biết ở giáp Trung Thành, làng Kiên Trung , tổng Kiên Trung , phủ Hải
						Hậu , tỉnh Nam Định.
					</p>
					<p className="m-9">
						Mỗi năm đến 25 tháng chạp, trong làng họ nào cũng có chạp họ, tất cả
						những người trong họ cử người tới nhà ông Thủ tộc, để nghiêm chỉnh
						tưởng nhớ đến Gia Tiên, đọc gia phả, ghi thêm con cháu mới sinh,
						cùng ghi lại những người quá cố, những con gái trong họ xuất gia,
						các chàng trai của họ lập gia đình, có tục lệ nộp cheo khao họ.
						Những họ có Từ đường hương hỏa, lợi tức thu khá, họ tổ chức ăn uống
						cỗ bàn, vừa vừa thì xôi, xôi phần biếu, hẹp ra thì trầu cau trà
						nước.
					</p>
					<p className="m-9">
						Ai bỏ không tới dự, sẽ tuyên đọc bắt khoán v.v.. hay coi như phạm
						lỗi với gia tiên. Xế trưa khi hội họp gần mãn, những người đàn ông
						trai tráng cùng nhau mộng cuốc lên nghĩa địa sửa mộ gia Tiên, Ông
						Bà, Cha Mẹ v.v....
					</p>
					<p className="m-9">
						Ngày đó ai đi qua cánh đồng nghĩa trang phần mộ, thấy đông đảo anh
						em bà con đi lại coi như trẩy hội.
					</p>
					<p className="m-9">
						Rồi dần dần lớn lên với thời gian gặp tai biến, ngày hội chạp họ đó
						cũng phai dần, đến độ không còn nữa. Lý do cũng dễ hiểu, người tha
						phương lập nghiệp, kẻ Bắc người Nam, người lên rừng kẻ xuống biển,
						thậm chí có kẻ ở đất khách quê người, chả còn hy vọng anh em con
						cháu họp lại để tưởng niệm Gia Tiên, sửa sang phần mộ.
					</p>
					<p className="m-9">
						Chúng cháu đây cũng ở trong số đông đó. Nghĩ đến xóm làng xưa kia là
						nơi "chôn nhau (rau) cắt rốn", bao nhiêu kỷ niệm êm đềm, buồn vui
						khôn tả.
					</p>
					<p className="m-9">
						Ngày nay mỗi người một nơi xa cách, có khi hàng muôn nghìn dặm, biết
						bao giờ có ngày họp nhau được nữa, mà còn mất anh em họ đương là
						khác. Chúng cháu tin chắc anh em con cháu các họ khác vì liên hệ xa
						gần, gồm các họ MAI, NGÔ, BÙI, LƯƠNG, TRỊNH, NGUYỄN, TRẦN, ĐINH,
						PHAN, VŨ ... mà chúng cháu ghi ra đây, cũng cùng tâm trạng ... Nghĩ
						đên mà mủi lòng ngao ngán, không biết mộ GIA TIÊN bây giờ còn đây
						nữa không ? hay cũng tan biến với thời gian tàn phá. Còn ai mỗi năm
						con cháu tới trông nom sửa sang phần mộ, chắc anh em con cháu các họ
						cũng xót xa lòng dạ.
					</p>
					<p className="m-9">
						Chúng cháu còn biết cách nào hơn là tìm kiếm chú bác anh em để ghi
						laị họ đương.
					</p>
					<p className="m-9">
						Từ Nam hưng, Bùi-môn, Trung Chánh. Đắc Lộ, Tân Phú Việt, Nam Nghĩa,
						Lộc Hòa, Bùi Phát, Gò Vấp, Hàng Xanh, Châu Bình, Long Bình, Ngã Ba
						Vũng Tàu, Tân Mai, Bùi Thái, Đức Hiệp, Hòa Bình, Bầu Cá, Xuân Lộc,
						Hưng Bình, Võ Dõng, Gia Kiệm, Đức Long, Dốc Mơ, Phương Phú, Ngọc
						Lâm, Đà Lạt, Nha Trang, Banmêthuột, Kim-Châu Phát, Giang Sơn về Thái
						Xuân, Phước Lộc, Hải Sơn, Bà Rịa, Tân định, Tân Sa Châu sang Khánh
						Hội, Xóm Chiếu v.v...
					</p>
					<p className="m-9">
						Đâu đâu chú bác anh em cũng hưởng ứng nồng nhiệt, đường xá xa xôi
						cách trở, xe cộ không ngại. Song khi về sắp đặt hàng họ không được
						trực tiếp từ trên xuống dưới, nên chúng cháu trộm phép tưởng tượng
						ra một vị THƯỢNG TỔ rồi tất cả các ngành qui vào đó. Gia đình nào
						chỉ biêt đên ông thì ghi vào ô ÔNG, còn từ TỔ trở lên bỏ trống, sau
						này ai sưu tầm thêm được từ TỔ trở lên sẽ điền vào .
					</p>
					<p className="m-9">
						Những điều chúng cháu ghi trên, gọi là thắp nén hương lòng, tỏ bày
						chân thành cung kính nhớ ơn.
					</p>
					<p className="m-9">
						Mong khi con cháu xem đến sổ này, ai là đầu ngành, xin cố gắng tiếp
						tục liên lạc anh em trong họ, mỗi năm cũng chỉ ngày 25 tháng chạp
						hoặc tháng giêng để tưởng niệm GIA TIÊN.
					</p>
					<p className="m-9">
						Cầu nguyện cho trong họ, kẻ còn sống được bằng an hồn xác, người quá
						cố được siêu thoát. Tổ quốc phú cường toàn dân hạnh phúc.
					</p>
					<p className="pl-6">Vì ai nay mới có mình,</p>
					<p className="pl-6">Mẹ cha tiên tổ công trình xiết bao.</p>
					<p className="pl-6">Ơn này sánh với trời cao.</p>
					<p className="pl-6">Hai bên cùng nặng nhớ ghi tâm hồn.</p>
					<p className="m-9">
						Và để cháu, chắt,chút,... có được sổ sách lưu truyền mai hậu. Mặc
						dầu nhiều nơi cháu chƯa thể tới được, vì xa xôi cách trở nọ kia.
						Thôi, gọi là có còn hơn không, tuy nhiều thiếu sót.
					</p>
					<p className="m-9">
						Xin TIÊN TỔ chấp nhận sự thiếu sót ngoài ý muốn của cháu.
					</p>
					<p className="m-9">Mong niệm tình tha thứ</p>
					<p className="m-9">Xin cúng vái Tiên Tổ. </p>
				</TabPanel>
			</TabView>
		</Card>
	);
}

export default FrontPage;
