import React, { useState, useEffect } from "react"; import { useRef } from "react";
import clsx from "clsx";
import useLazyLoad from "./useLazyLoad";
import { PhotoCard } from './PhotoCard';
import { LoadingPosts } from './LoadingPosts';
import { useLocation } from "react-router-dom";
import { debug } from "../../utils/ConsoleHelper";
//import "primereact/resources/themes/lara-light-indigo/theme.css";
import PreviewModal from "./PreviewModal";

let NUM_PER_PAGE = 6;
let TOTAL_PAGES = 30;

const Posts = ({ directorystr }) => {
    let urllocation = useLocation();
    const dirstr = new URLSearchParams(urllocation.search).get('dir')
    debug(directorystr)
    const [imagesPreviewUrls, setImagesPreviewUrls] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);
    const [dragId, setDragId] = useState("");
    const [clickedImg, setClickedImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    let postdata = {}
    const [images, setImages] = useState([])
    const triggerRef = useRef(null);
    const onGrabData = (currentPage) => {
        // This would be where you'll call your API
        return new Promise((resolve) => {
            setTimeout(() => {
                const posts = postdata["data"] || images || {}
                const data = posts?.slice(
                    ((currentPage - 1) % TOTAL_PAGES) * NUM_PER_PAGE,
                    NUM_PER_PAGE * (currentPage % TOTAL_PAGES)
                );
                debug(data);
                if (data)
                    resolve(data);
            }, 3000);
        });

    };

    const { data, loading } = useLazyLoad({ triggerRef, onGrabData });

    const get = async (url) => {
        try {
            const response = await fetch(url, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    id: "",
                },
            }).then((res) => {
                return res.json();
            });
            return [response, null];
        } catch (error) {
            return [null, error];
        }
    }

    useEffect(() => {
        async function getImagesFromServer(dirstr) {
            let imagesPreviewUrls = []
            const url = process.env.REACT_APP_SERVER_URL + `/fetchImagesFromDirectory/${dirstr}`;
            const [response, error] = await get(url);
            if (error) {
                alert(error);
                //setLoading(false);
            } else {
                if (response) {
                    let tmpfiles = imagesPreviewUrls;
                    let idx = 0
                    if (response && response.length > 0) {
                        response.map((rec) => {
                            // const tmp = `data:image/jpeg;base64,${rec.file}`;
                            const record = {
                                "owner": "NgoFam",
                                "imageUrl": process.env.REACT_APP_IMG_SERVER_URL + "/images/" + rec,
                                id: idx++,
                                filename: rec,
                                file: process.env.REACT_APP_IMG_SERVER_URL + "/images/" + rec,
                                id: rec,
                            };
                            tmpfiles = [...tmpfiles, record];
                        });
                    }
                    TOTAL_PAGES = tmpfiles.length / NUM_PER_PAGE;
                    postdata = { data: tmpfiles }
                    setImages(tmpfiles)
                    setPreviewImages(tmpfiles);
                    setImagesPreviewUrls(tmpfiles);
                    //return tmpfiles;
                }
            }
        }
        getImagesFromServer(dirstr)
    }, []);

    const handleClick = (item, index) => {
        setCurrentIndex(index);
        setClickedImg(item.file);
    };

    const handelRotationRight = () => {
        const totalLength = previewImages.length;
        if (currentIndex + 1 >= totalLength) {
            setCurrentIndex(0);
            const newUrl = previewImages[0].file;
            setClickedImg(newUrl);
            return;
        }
        const newIndex = currentIndex + 1;
        const newUrl = previewImages.filter((item) => {
            return previewImages.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].file;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
    };

    const handelRotationLeft = () => {
        const totalLength = previewImages.length;
        if (currentIndex === 0) {
            setCurrentIndex(totalLength - 1);
            const newUrl = previewImages[totalLength - 1].file;
            setClickedImg(newUrl);
            return;
        }
        const newIndex = currentIndex - 1;
        const newUrl = previewImages.filter((item) => {
            return previewImages.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].file;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
    };

    const handleOver = (ev) => {
        ev.preventDefault();
    };

    const handleDrag = (ev) => {
        setDragId(ev.currentTarget.id);
    };

    const handleDrop = (ev) => {
        ev.preventDefault();
        const dragImage = previewImages.find((image) => image.id == dragId);
        const dropImage = previewImages.find(
            (image) => image.id == ev.currentTarget.id
        );
        const arr = moveItem(dragImage.id - 1, dropImage.id - 1);

        setPreviewImages(arr);
    };

    const moveItem = (from, to) => {
        const f = previewImages.splice(from, 1)[0];
        previewImages.splice(to, 0, f);
        return previewImages;
    };

    return (
        <>
            <div className="container p-4">

                <div className="grid grid-cols-6 gap-4 content-start">
                    {data.map((image, index) => {
                        return <PhotoCard owner={image["owner"]} imageUrl={image["imageUrl"]}
                            onClick={() => handleClick(image, index)} />
                    })}
                </div>
                <div ref={triggerRef} className={clsx("trigger", { visible: loading })}>
                    <LoadingPosts />
                </div>
                <div>
                    {clickedImg && (
                        <PreviewModal
                            clickedImg={clickedImg}
                            handelRotationRight={handelRotationRight}
                            setClickedImg={setClickedImg}
                            handelRotationLeft={handelRotationLeft}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
export default Posts